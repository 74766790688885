<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="processStep"
      :items-per-page="20"
      :sort-by="['Number']"
      :sort-desc="[false]"
      item-key="id"
      class="elevation-1"
      disable-pagination
      dense
      :hide-default-footer="true"
      :loading="isFindProcessStepPending"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.Color`]="{ item }">
        <span
          v-for="color in group.find((f) => f.id == item.GroupId)?.allColors"
          :key="color"
        >
          <v-icon v-if="item.group?.Loop" small :color="color"
            >mdi-sync-circle</v-icon
          >
          <v-icon v-else small :color="color">mdi-circle</v-icon>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    variant: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'Color',
          width: '80px',
        },
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'Number',
          width: '50px',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
      ],
    };
  },
  mixins: [
    makeFindMixin({
      service: 'process-step',
      watch: 'variant',
    }),
    makeFindMixin({
      service: 'group',
      watch: 'variant',
    }),
  ],

  computed: {
    processStepParams() {
      return {
        query: {
          VariantId: this.variant,
        },
      };
    },
    groupParams() {
      return {
        query: {
          VariantId: this.variant,
        },
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
