<template>
  <div>
    <v-switch
      v-model="showAll"
      label="Show All"
      hide-details
      class="mb-5 ml-4"
    ></v-switch>

    <v-data-table
      :headers="headers"
      :items="filteredProcesses"
      :items-per-page="20"
      :sort-by="['Number']"
      :sort-desc="[false]"
      item-key="id"
      class="elevation-1"
      disable-pagination
      show-select
      show-expand
      single-expand
      @item-selected="clickedRow"
      @toggle-select-all="clickedAll"
      v-model="selected"
      dense
      :hide-default-footer="true"
      :loading="isPending || selectPending"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.color`]="{ item }">
        <span
          v-for="color in group.find((f) => f.id == item.GroupId)?.allColors"
          :key="color"
        >
          <v-icon v-if="item.group.Loop" small :color="color"
            >mdi-sync-circle</v-icon
          >
          <v-icon v-else small :color="color">mdi-circle</v-icon>
        </span>
      </template>
      <template v-slot:[`item.Fields`]="{ item }">
        <TestScenarioStepFields
          v-if="!selectPending && item.TestScenarioLineStepLinked"
          :processStep="item"
          :disabled="!item.TestScenarioLineStepLinked"
        />
      </template>
      <template v-slot:[`item.TestRemark`]="{ item }">
        <v-text-field
          v-model="item.TestRemark"
          label=""
          flat
          dense
          :disabled="!item.TestScenarioLineStepLinked"
          class="my-1"
          single-line
          outlined
          hide-details
          width="200"
          @change="saveLineStep(item)"
        ></v-text-field
      ></template>
      <template v-slot:[`item.status.Testable`]="{ item }">
        <v-icon v-if="item.status.Testable" color="green">mdi-check</v-icon>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }"
            ><v-icon color="red" v-bind="attrs" v-on="on"
              >mdi-close</v-icon
            > </template
          >{{ item.TestableSteps }}/{{ item.NrOfSteps }}</v-tooltip
        >
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" v-if="item?.Description">
          <v-row
            ><v-col class="mb-0 pb-0"
              ><span
                class="ma-3 ql-editor"
                v-html="item?.Description"
              ></span></v-col
          ></v-row>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import TestScenarioStepFields from '@/components/TestScenarios/TestScenario/TestScenarioStepFields.vue';
export default {
  props: {
    variant: {
      type: Object,
      required: true,
    },
    stepsToAdd: {
      type: Array,
      required: false,
    },
    testScenarioLine: {
      type: Object,
      required: true,
    },
    addModus: {
      type: Boolean,
      required: true,
    },
  },
  components: { TestScenarioStepFields },
  data() {
    return {
      steps: null,
      selected: [],
      showAll: false,
      firstInit: true,
      lastFilteredProcesses: [], // Cache the last result
      reload: 0,
      selectPending: false,
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'color',
          width: '80px',
        },
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'Number',
          width: '50px',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Fields',
          align: 'start',
          sortable: false,
          value: 'Fields',
          width: '250px',
        },
        {
          text: 'Test Remark',
          align: 'start',
          sortable: false,
          value: 'TestRemark',
          width: '250px',
        },
        {
          text: 'Testable',
          align: 'center',
          sortable: false,
          value: 'status.Testable',
        },
        {
          text: '',
          align: 'end',
          value: 'data-table-expand',
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line-step',
    }),
    makeFindMixin({
      service: 'process-step',
      watch: ['variant.id'], // Removed reload as watcher not necessiare now that we auto create the lines?
    }),
    makeFindMixin({
      service: 'group',
      watch: ['variant.id'],
    }),
  ],
  watch: {
    processStep() {
      this.updateFilteredProcesses();
    },
    showAll() {
      this.updateFilteredProcesses();
    },
    isFindProcessStepPending: function () {
      this.initialize();
    },
    'testScenarioLine.id': function () {
      this.initialize();
    },
  },
  computed: {
    isPending() {
      return (
        this.isFindProcessStepPending || this.isFindTestScenarioLineStepPending
      );
    },
    testScenarioLineStepParams() {
      if (this.testScenarioLine.id) {
        return { query: { TestScenarioLineId: this.testScenarioLine.id } };
      } else return { query: { id: -1 } };
    },
    processStepParams() {
      return { query: { VariantId: this.variant.id } };
    },
    groupParams() {
      return { query: { VariantId: this.variant.id } };
    },
    filteredProcesses() {
      if (!this.isPending) {
        this.updateFilteredProcesses();
      }
      return this.lastFilteredProcesses;
    },
  },
  methods: {
    updateFilteredProcesses() {
      if (!this.showAll) {
        this.lastFilteredProcesses = this.processStep.filter(
          (f) => f.TestScenarioLineStepLinked == true
        );
      } else {
        this.lastFilteredProcesses = this.processStep;
      }
    },
    initialize() {
      if (!this.isFindProcessStepPending) {
        const ids = this.testScenarioLineStep.map((item) => item.ProcessStepId);
        this.selected = this.processStep.filter((f) => ids.includes(f.id));

        // Add TestScenarioLineStep data to process
        for (const processStep of this.processStep) {
          const testScenarioLineStep = this.testScenarioLineStep.find(
            (f) =>
              f.ProcessStepId == processStep.id &&
              f.TestScenarioLineId == this.testScenarioLine.id
          );
          if (testScenarioLineStep) {
            processStep.TestRemark = testScenarioLineStep.TestRemark;
            processStep.TestScenarioLineStepLinked = true;
            processStep.TestScenarioLineStepId = testScenarioLineStep.id;
          } else {
            processStep.TestRemark = null;
            processStep.TestScenarioLineStepLinked = false;
            processStep.TestScenarioLineStepId = null;
          }
        }

        if (this.firstInit) {
          if (this.addModus) {
            this.showAll = true;
          } else {
            this.showAll = false;
          }
        }
      }
    },
    filterProcessSteps() {
      this.showAll = !this.showAll;
    },
    async clickedRow(value, bulk = false) {
      this.firstInit = false;

      if (this.testScenarioLine.id) {
        if (value.value) {
          // Add line to group step
          try {
            const saved = await feathersClient
              .service('test-scenario-line-step')
              .create({
                TestScenarioLineId: this.testScenarioLine.id,
                ProcessStepId: value.item.id,
                TestRemark: value.item.TestRemark,
                GroupId: value.item.GroupId,
              });
            value.item.TestScenarioLineStepLinked = true;
            value.item.TestScenarioLineStepId = saved.id;
            // handleSaveResponse(value.item.Name, 'Step', 'Added');
            if (!bulk) await this.refresh();
          } catch (error) {
            handleErrorResponse(error);
          }
        } else {
          let Steps = this.testScenarioLineStep.find(
            (f) =>
              f.TestScenarioLineId == this.testScenarioLine.id &&
              f.ProcessStepId == value.item.id
          );
          value.item.TestScenarioLineStepLinked = false;
          value.item.TestScenarioLineStepId = null;
          if (Steps) {
            try {
              await feathersClient
                .service('test-scenario-line-step')
                .remove(Steps.id);
              // handleSaveResponse(value.item.Name, 'Step', 'Removed');
              if (!bulk) await this.refresh();
            } catch (error) {
              handleErrorResponse(error);
            }
          }
        }

        if (!bulk) await this.renumber();
      } else {
        // for new scenario keep steps to save later
        if (value.value) {
          this.steps.push(value.item);
        } else {
          this.steps = this.steps.filter((f) => f.id != value.item.id);
        }
      }

      if (!bulk) {
        // this.stepsToAdd = steps;
        this.reload = this.reload + 1;
        this.$emit('updateSteps', this.steps);
      }
    },
    async renumber() {
      // Renumber
      for (const [index, step] of this.testScenarioLineStep
        .sort((a, b) => a.process_step.Number - b.process_step.Number)
        .filter((f) => f.TestScenarioLineId == this.testScenarioLine.id)
        .entries()) {
        step.Number = index + 1;
        try {
          await step.save();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    },
    async clickedAll(value) {
      this.selectPending = true;
      for (const v of value.items) {
        let item = {};
        item.item = v;
        item.value = value.value;
        await this.clickedRow(item, true);
      }
      await this.refresh();
      await this.renumber();
      this.reload = this.reload + 1;
      this.$emit('updateSteps', this.steps);
      this.selectPending = false;
    },
    async saveLineStep(item) {
      try {
        const saved = await feathersClient
          .service('test-scenario-line-step')
          .patch(item.TestScenarioLineStepId, {
            TestRemark: item.TestRemark,
          });
        handleSaveResponse(saved.TestRemark, 'Test Step', 'updated');
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    async refresh() {
      const { TestScenarioLine, TestScenarioLineStep } = this.$FeathersVuex.api;
      await TestScenarioLineStep.find({
        query: { TestScenarioLineId: this.testScenarioLine.id },
      });
      await TestScenarioLine.find({
        query: { VariantId: this.variant.id },
      });
    },
  },
  mounted() {
    this.steps = this.stepsToAdd;
  },
};
</script>

<style lang="scss" scoped></style>
