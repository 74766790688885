<template>
  <v-list dense class="list ma-0">
    <v-list-item v-for="(field, index) in stepFields" :key="index">
      <v-list-item-content>
        <v-list-item-title>
          {{ field?.field?.Name }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="field.fieldValues"
          ><span v-for="(value, index) in field.fieldValues" :key="index">
            {{ value?.Value }}
            <span v-if="value?.Code">({{ value?.Code }})</span></span
          >
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line-step-field',
      watch: 'keyReload',
    }),
  ],
  props: {
    testScenarioLineStep: {
      type: Object,
      required: true,
    },
    keyReload: {
      type: Number,
      required: true,
    },
  },
  computed: {
    testScenarioLineStepFieldParams() {
      return {
        query: { TestScenarioLineStepId: this.testScenarioLineStep.id },
      };
    },
    stepFields() {
      const result = [];
      const fieldMap = new Map();

      this.testScenarioLineStepField.forEach((item) => {
        const fieldValueList = item.fieldvaluelist;
        const fieldId = fieldValueList?.FieldId;

        if (!fieldMap.has(fieldId)) {
          fieldMap.set(fieldId, {
            field: fieldValueList?.field,
            fieldValues: [],
          });
          result.push(fieldMap.get(fieldId));
        }

        fieldMap.get(fieldId).fieldValues.push(fieldValueList);
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  background-color: transparent !important;
}
</style>
