<template>
  <div v-if="!isFindGroupPending">
    <div v-if="group?.length == 0">No Groups Defined</div>
    <v-treeview
      v-else
      :items="buildTree(group, null)"
      item-text="Name"
      item-children="children"
      open-all
      activatable
      item-key="id"
      :active.sync="selectedNode"
      return-object
    >
      <template v-slot:prepend="{ item }">
        <v-icon v-if="item.Loop" :color="item.Color">mdi-sync-circle</v-icon>
        <v-icon v-else :color="item.Color">mdi-circle</v-icon>
      </template>

      <template v-slot:append="{ item }">
        <v-icon
          v-if="$can('update', 'group')"
          small
          class="mr-2"
          @click="clickEdit(item)"
          color="primary"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-treeview>
    <div v-if="selectedNode?.length > 0">
      <v-btn icon @click="changeOrder('up', selectedNode[0].id)"
        ><v-icon>mdi-chevron-up</v-icon></v-btn
      >
      <v-btn icon @click="changeOrder('up', selectedNode[0].id)"
        ><v-icon>mdi-chevron-down</v-icon></v-btn
      >
    </div>
    <GroupEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
      :variant="variant.id"
    />
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import GroupEdit from './GroupEdit.vue';

export default {
  mixins: [
    makeFindMixin({
      service: 'group',
      watch: 'variant.id',
    }),
  ],
  components: { GroupEdit },
  props: {
    variant: {
      type: Object,
      required: true,
    },
  },
  watch: {
    selectedNode: function () {
      this.$emit('selectionChanged', this.selectedNode);
    },
  },
  data() {
    return {
      currentItem: null,
      detailDialog: false,
      selectedNode: [],
    };
  },
  methods: {
    buildTree(data, parentId) {
      return data
        .filter((item) => item.ParentGroupId == parentId)
        .sort((a, b) => a.Order - b.Order)
        .map((item) => {
          const children = this.buildTree(data, item.id);
          return {
            ...item,
            children: children.length ? children : null, // Only add children if they exist
          };
        });
    },
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    changeOrder(direction, id) {
      let list = this.group.filter(
        (f) =>
          f.ParentGroupId == this.group.find((f) => f.id == id)?.ParentGroupId
      );
      const currentIndex = list.findIndex((item) => item.id === id);

      // Check if the direction is valid
      if (direction !== 'up' && direction !== 'down') {
        console.error('Invalid direction. Use "up" or "down".');
        return list;
      }

      // Check if the current index is within bounds
      if (currentIndex < 0 || currentIndex >= list.length) {
        console.error('Invalid current index.');
        return list;
      }

      // Determine the target index based on the direction
      let targetIndex =
        direction === 'up' ? currentIndex - 1 : currentIndex + 1;

      // Check if the target index is within bounds
      if (targetIndex < 0 || targetIndex >= list.length) {
        console.error('Cannot move in the specified direction.');
        return list;
      }

      // Swap the Order values of the current object and the target object
      let tempOrder = list[currentIndex].Order;
      list[currentIndex].Order = list[targetIndex].Order;
      list[targetIndex].Order = tempOrder;

      // Swap the objects in the list
      let temp = list[currentIndex];
      list[currentIndex] = list[targetIndex];
      list[targetIndex] = temp;

      // Sort the list based on the Order field to maintain the correct order
      list.sort((a, b) => a.Order - b.Order);

      console.log(list);
    },
  },
  computed: {
    groupParams() {
      return { query: { VariantId: this.variant.id } };
    },
  },
};
</script>

<style lang="scss" scoped></style>
