/* eslint-disable no-prototype-builtins */
function applyDark(item) {
  if (item) {
    var color = item.charAt(0) === '#' ? item.substring(1, 7) : item;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.4 ? false : true;
  } else {
    return false;
  }
}

function createNestedObject(obj) {
  const result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const keys = key.split('.');
      let currentLevel = result;

      keys.forEach((nestedKey, index) => {
        if (!currentLevel.hasOwnProperty(nestedKey)) {
          if (index === keys.length - 1) {
            currentLevel[nestedKey] = obj[key];
          } else {
            currentLevel[nestedKey] = {};
          }
        }

        currentLevel = currentLevel[nestedKey];
      });
    }
  }

  return result;
}

// function getTrend2(numbers) {
//   var rows = [];
//   var totalNumbers = numbers.length;
//   var currentValueNumber = 1;
//   var n = 0;

//   while (n < numbers.length) {
//     rows.push({ row: currentValueNumber, number: numbers[n] });
//     currentValueNumber += 1;
//     n += 1;
//   }

//   var sumLines = 0;
//   var sumNumbers = 0;
//   var sumMix = 0;
//   var squareOfs = 0;

//   for (var k of rows) {
//     sumLines += k.row;
//     sumNumbers += k.number;
//     sumMix += k.row * k.number;
//     squareOfs += k.row ** 2;
//   }

//   var a = totalNumbers * sumMix - sumLines * sumNumbers;
//   var b = totalNumbers * squareOfs - sumLines ** 2;
//   var c = a / b;

//   return c;
// }

function getTrend(numbers) {
  if (numbers.length < 2) {
    return 0; // Not enough data to determine trend strength.
  }

  // Calculate the linear regression slope
  const n = numbers.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXSquare = 0;

  for (let i = 0; i < n; i++) {
    sumX += i;
    sumY += numbers[i];
    sumXY += i * numbers[i];
    sumXSquare += i * i;
  }

  const slope = (n * sumXY - sumX * sumY) / (n * sumXSquare - sumX * sumX);

  // Use the slope to determine the trend strength
  const trendStrength = slope * 100; // Multiply by 100 for a more interpretable value

  return trendStrength;
}

export { applyDark, createNestedObject, getTrend };
