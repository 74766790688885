<template>
  <div v-if="variant && process">
    <div class="caption">
      <router-link
        v-if="$store.state.activeEnd2End.Name"
        :to="{
          name: 'Process',
          params: { id: $store.state.activeEnd2End.id },
        }"
        >E2E{{ $store.state.activeEnd2End.Number }} -
        {{ $store.state.activeEnd2End.Name }}</router-link
      ><v-icon v-if="$store.state.activeEnd2End.Name" small
        >mdi-chevron-right</v-icon
      >{{ process.department.Name
      }}<v-menu open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small
            >mdi-chevron-double-right</v-icon
          >
        </template>

        <v-list dense>
          <v-list-item v-for="process in fullProcessList" :key="process.id">
            <v-list-item-title
              ><router-link
                :to="{
                  name: 'processDetail',
                  params: { id: process.id, number: process.Number },
                }"
                >{{ process.Number }}. {{ process.Name }}</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <router-link
        :to="{
          name: 'processDetail',
          params: { id: process.id, number: process.Number },
        }"
        >{{ $appConfig.customer.prefix
        }}{{ process.Number.toString().padStart(4, '0') }} -
        {{ process.Name }}</router-link
      >

      <v-menu open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small
            >mdi-chevron-double-right</v-icon
          >
        </template>

        <v-list dense>
          <v-list-item v-for="variant in sortedVariants" :key="variant.id">
            <v-list-item-title
              ><router-link
                :to="{ name: 'processStep', params: { id: variant.id } }"
                >{{ variant.Number }}. {{ variant.Name }}</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <router-link :to="{ name: 'processStep', params: { id: variant.id } }"
        ><b>{{ variant.Number }} - {{ variant.Name }}</b></router-link
      >
      <v-icon small class="ml-2 mr-2">mdi-forward</v-icon>
      <router-link
        v-if="$feature('specification')"
        :to="{
          name: 'specification',
          params: { id: variant.id, type: 1 },
        }"
        >Specifications ({{
          variant.specifications
            ? variant.specifications.filter(
                (s) =>
                  s.specification_type.Active == true && s.Obsolete == false
              ).length
            : 0
        }})</router-link
      >
      <router-link
        v-else
        :to="{
          name: 'processRequirement',
          params: { id: variant.id, type: 1 },
        }"
        >Requirements ({{
          variant.requirements ? variant.requirements.length : 0
        }})</router-link
      >
      <v-icon v-if="sharePointUrl" small class="ml-2 mr-2">mdi-forward</v-icon>
      <a v-if="sharePointUrl" target="_blank" :href="sharePointUrl"
        >Documents</a
      >
    </div>

    <v-alert border="bottom">
      <div class="subtitle-1">
        {{ fullProcess }}
        <v-icon
          v-if="checkEditVariant(variant)"
          small
          class="ml-2 mr-2 mb-1"
          @click="clickEditVariant(variant)"
          color="primary"
        >
          mdi-pencil
        </v-icon>
      </div>
    </v-alert>

    <v-row class="ma-0 pa-0"
      ><v-card flat v-if="variant.Input" class="mx-5"
        ><v-card-title class="text-subtitle-1 pb-1 pl-0 pt-0"
          >Input</v-card-title
        >
        <span
          v-html="variant.Input"
          class="text-body-2 ma-0 pa-0 ql-editor"
        ></span></v-card
    ></v-row>
    <v-row class="ma-0 pa-0"
      ><v-card flat v-if="variant.Output" class="mx-5"
        ><v-card-title class="text-subtitle-1 pb-1 pl-0">Output</v-card-title>
        <span
          v-html="variant.Output"
          class="text-body-2 ma-0 pa-0 ql-editor"
        ></span></v-card></v-row
    ><v-row
      justify="center"
      v-if="
        variant.process.ExternalDocumentId &&
        !embedToken &&
        $feature('lucidChartIntegration')
      "
    >
      <v-skeleton-loader
        min-width="1000px"
        min-height="520px"
        type="image button@2"
      ></v-skeleton-loader>
    </v-row>
    <v-row
      justify="center"
      class="mb-5"
      v-if="
        $feature('cloudinaryChartIntegration') &&
        images &&
        images.length > 0 &&
        !embedToken
      "
    >
      <v-carousel
        :height="images && images.length > 1 ? '600px' : '500px'"
        width="100%"
        :hide-delimiters="images && images.length > 1 ? false : true"
        :show-arrows="images && images.length > 1 ? true : false"
      >
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-carousel-item
              class="mb-15"
              v-for="(item, i) in images"
              :key="i"
              :src="item"
              contain
              max-height="500"
              reverse-transition="fade-transition"
              transition="fade-transition"
              @click="openWindow(item)"
            ></v-carousel-item>
          </v-col>
        </v-row>
      </v-carousel>
    </v-row>
    <div v-if="$feature('drawIOChartIntegration')">
      <v-row>
        <v-col class="mt-5 mb-10"><DrawIO :variantId="variant.id" /></v-col
      ></v-row>
    </div>
    <v-row
      v-if="$feature('lucidChartIntegration') && embedToken"
      class="mb-5"
      justify="center"
    >
      <iframe
        frameBorder="0"
        width="1000px"
        height="500px"
        :src="LucidUrl"
        style="border: 0"
      ></iframe>
    </v-row>
    <v-row class="mb-4"
      ><v-spacer></v-spacer>
      <div v-if="embedToken">
        <v-icon @click="openWindow(LucidUrl)">
          mdi-image-search-outline
        </v-icon>

        <v-icon
          v-if="$can('update', 'process-step', 'Name')"
          class="ml-5"
          @click="getSteps(variant)"
        >
          mdi-table-sync
        </v-icon>
      </div>
    </v-row>
    <v-row>
      <div v-if="ErpApplications?.length > 0">
        <v-progress-circular
          v-if="this.loadingERP"
          indeterminate
          :size="15"
          :width="1"
          class="ml-5"
        ></v-progress-circular>
        <v-card flat class="mx-5"
          ><v-card-title class="text-subtitle-1 pb-1 pl-0 pt-0"
            >Application</v-card-title
          >

          <v-list dense class="pl-0 ml-0"
            ><v-list-item v-for="app in ErpApplications" :key="app.id"
              ><v-list-item-content
                ><v-tooltip top open-delay="1000">
                  <template v-slot:activator="{ on, attrs }"
                    ><v-list-item-title v-bind="attrs" v-on="on">
                      <a v-if="app.Link" :href="app.Link" target="_blank">
                        <span v-if="app.Customized">*</span>
                        {{ app.Name }}
                      </a>
                      <span v-else>{{ app.Name }}</span>
                    </v-list-item-title></template
                  >{{ app.Name }}</v-tooltip
                ><v-tooltip top open-delay="1000">
                  <template v-slot:activator="{ on, attrs }"
                    ><v-list-item-subtitle v-bind="attrs" v-on="on"
                      >{{ app.Filter }}
                    </v-list-item-subtitle></template
                  >{{ app.Filter }}</v-tooltip
                ></v-list-item-content
              ></v-list-item
            >
          </v-list></v-card
        >
      </div></v-row
    >

    <section class="mt-3">
      <v-progress-linear
        v-if="isFindProcessStepPending"
        indeterminate
        color="secondary"
      ></v-progress-linear>
      <!-- <draggable v-model="items" v-bind="dragOptions" :move="onMoveCallback"> -->
      <v-row class="mb-5" v-if="processStep?.length > 2">
        <v-btn
          v-if="$can('create', 'process-step')"
          class="mx-2"
          fab
          small
          color="primary"
          @click="clickAdd()"
        >
          <v-icon dark> mdi-plus </v-icon> </v-btn
        ><v-spacer></v-spacer>
        <v-icon @click="clickAddGroup">mdi-format-list-group-plus</v-icon>
        <v-icon
          v-if="$can('update', 'process-step', 'Number')"
          class="ml-5"
          @click="showReorder = true"
        >
          mdi-order-numeric-ascending
        </v-icon></v-row
      >
      <div class="border-xl">
        <v-card
          class="border-xl mb-4"
          v-for="(item, i) in processStep"
          :key="item.id"
        >
          <v-card-title
            :class="{
              TitleBackground: true,
              obsolete: item.Obsolete,
            }"
            :style="
              item.GroupId != null
                ? accentStyle(
                    group.find((f) => f.id == item.GroupId)?.allColors
                  )
                : ''
            "
          >
            <span
              v-for="color in group.find((f) => f.id == item.GroupId)
                ?.allColors"
              :key="color"
            >
              <v-icon v-if="item.group.Loop" small :color="color"
                >mdi-sync-circle</v-icon
              >
              <v-icon v-else small :color="color">mdi-circle</v-icon>
            </span>
            <v-tooltip bottom open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item.Number }} - {{ item.Name }}</span
                >
              </template>
              <span>{{ fullNumber(item) }}</span>
            </v-tooltip>

            <span v-if="item.priority" class="overline ml-4"
              >({{ item.priority.Name }})</span
            >
            <v-icon
              v-if="
                !item.ExternalId &&
                ($feature('lucidChartIntegration') ||
                  $feature('drawIOChartIntegration')) &&
                $can('update', 'process-step')
              "
              small
              class="ml-2"
              color="red"
            >
              mdi-link
            </v-icon>
            <v-icon
              v-if="$can('update', subject('process-step', item))"
              small
              class="ml-5"
              @click="clickEdit(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>

            <div v-if="item && item.issueStatus && item.issueStatus.length > 0">
              <v-icon
                class="ml-5"
                small
                @click="clickAddIssue(item)"
                color="primary"
              >
                mdi-login-variant
              </v-icon>
              <StatusListIndividual
                class="ml-1"
                :statusList="item.issueStatus"
              />
            </div>
            <v-icon
              v-else
              class="ml-5"
              small
              @click="clickAddIssue(item)"
              color="primary"
            >
              mdi-login-variant
            </v-icon>
            <v-chip
              class="ma-2"
              label
              v-if="item && item.bugStatus && item.bugStatus.length > 0"
            >
              <v-icon
                small
                color="primary"
                @click="
                  currentItem = item;
                  showBugs = true;
                "
              >
                mdi-bug</v-icon
              >

              <StatusListIndividual class="ml-1" :statusList="item.bugStatus" />
            </v-chip>

            <v-icon
              v-if="item && item.nbrOfResponses > 0 && $can('read', 'response')"
              small
              class="ml-2"
              @click="clickResponses(item)"
              color="primary"
            >
              mdi-format-list-checks
            </v-icon>
            <v-icon
              v-if="$can('create', 'GPT')"
              class="ml-5"
              small
              @click="askChatGPT(item)"
              color="primary"
            >
              mdi-chat-outline
            </v-icon>
            <v-spacer></v-spacer>
            <v-menu>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="{ ...menu }"
                  v-if="item.status && !item.Obsolete"
                  class="ma-2"
                  :text-color="applyDark(item.status.Color) ? 'white' : 'black'"
                  :color="item.status.Color"
                  x-small
                >
                  {{ item.status.Status }}
                </v-chip>
              </template>
              <v-list dense style="max-height: 500px" class="overflow-y-auto">
                <v-list-item
                  v-for="(stat, index) in status"
                  :key="stat.id"
                  :value="index"
                  @click="assignStatus(item, stat.id)"
                >
                  <v-list-item-title>
                    <v-icon small class="mr-2" :color="stat.Color">
                      mdi-circle </v-icon
                    >{{ stat.Status }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <!-- eslint-disable vue/no-v-text-v-html-on-component -->
          <v-card-text
            :style="
              item.GroupId != null
                ? accentStyle(
                    group.find((f) => f.id == item.GroupId)?.allColors
                  )
                : ''
            "
            ><span
              v-html="item.Description"
              class="text-body-2 ma-0 pa-0 ql-editor"
            ></span>
          </v-card-text>
          <v-card-actions> </v-card-actions>
          <!-- <v-card-text>{{ item.Description }} </v-card-text> -->

          <v-expand-transition>
            <div v-show="item && item.fields && item.fields.length > 0">
              <caption class="ml-2 mb-1">
                Fields
              </caption>
              <v-data-table
                :headers="headers"
                :items="item.fields"
                sort-by="Number"
                dense
                disable-pagination
                hide-default-footer
                show-expand
                class="elevation-1"
              >
                <template v-slot:[`item.unlink`]="{ item }">
                  <v-icon
                    v-if="$can('delete', 'field-step')"
                    small
                    class="mr-2"
                    @click="unlinkField(item)"
                    color="primary"
                  >
                    mdi-link-off </v-icon
                  ><v-icon
                    v-if="$can('edit', 'field-step')"
                    small
                    class="mr-2"
                    @click="editField(item)"
                    color="primary"
                  >
                    mdi-pencil
                  </v-icon></template
                >
                <template v-slot:expanded-item="{ item }">
                  <td :colspan="headers.length">
                    <v-card width="auto" class="ma-10">
                      <FieldValueList :fieldId="item.id" />
                    </v-card>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-expand-transition>
          <v-card-actions
            ><v-icon
              v-if="$can('create', 'field-step')"
              class="mx-2 ml-1"
              @click="clickAddField(item)"
              small
            >
              mdi-playlist-plus
            </v-icon>

            <v-icon
              v-if="$can('create', 'field-step')"
              class="mx-2 ml-1"
              @click="clickAddNewField(item)"
              small
            >
              mdi-plus
            </v-icon>
            <v-spacer></v-spacer>

            <v-btn icon @click="toggle(i)">
              <v-icon small>{{ 'mdi-swap-horizontal' }}</v-icon>
            </v-btn>
            <Updated
              v-if="show[i]"
              class="mr-5 my-2"
              :updateDate="item.updatedAt"
              :userId="item.ModifiedById"
              table="process_step"
              :id="item.id"
            />
          </v-card-actions>
        </v-card>
      </div>
    </section>

    <v-row class="mt-3">
      <v-btn
        v-if="$can('create', 'process-step')"
        class="mx-2"
        fab
        small
        color="primary"
        @click="clickAdd()"
      >
        <v-icon dark> mdi-plus </v-icon> </v-btn
      ><v-spacer></v-spacer>
      <v-icon @click="clickAddGroup">mdi-format-list-group-plus</v-icon>
      <v-icon
        v-if="$can('update', 'process-step', 'Number')"
        class="ml-5"
        @click="showReorder = true"
      >
        mdi-order-numeric-ascending
      </v-icon></v-row
    >

    <v-dialog
      v-if="groupDialog"
      v-model="groupDialog"
      hide-overlay
      width="70vw"
      height="auto"
    >
      <Group :variant="variant" />
    </v-dialog>
    <ProcessStepEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
      :variant="variant.id"
    />
    <FieldListAdd
      v-if="detailDialogAddField"
      v-model="detailDialogAddField"
      :stepid="StepId"
    />
    <FieldEdit
      v-if="detailDialogNewField"
      v-model="detailDialogNewField"
      :processStep="currentItem"
    />
    <v-dialog
      v-if="issueDialog"
      v-model="issueDialog"
      hide-overlay
      width="90vw"
      height="auto"
      ><v-card height="90vh" class="pa-5">
        <IssueList
          v-if="issueDialog"
          v-model="issueDialog"
          :processStep="Step"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="importSteps" max-width="600px">
      <v-card flat>
        <v-card-title>
          <span class="headline">Import Steps</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers3"
                :items="compareImportedSteps"
                sort-by="Number"
                disable-pagination
                hide-default-footer
                show-select
                :item-class="row_classes"
                v-model="importSelected"
                ><template v-slot:[`header.arrow`]="{ header }">
                  <v-icon>mdi-arrow-right-bold</v-icon>{{ header.text }}
                </template></v-data-table
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="cancelImport()">Cancel</v-btn>
          <v-btn color="primary" @click="saveImport()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ReOrder v-if="showReorder" v-model="showReorder" :variantId="variant.id" />
    <Response
      v-if="processStep && processStep.length > 0 && showResponse"
      v-model="showResponse"
      :item="currentItem"
    />
    <VariantEdit
      v-if="detailVariantDialog"
      v-model="detailVariantDialog"
      :currentItem="variant"
      :process="variant.processId"
    />
    <FieldEdit
      v-if="detailDialogField"
      v-model="detailDialogField"
      :currentItem="currentItemField"
    />
    <BugList
      v-model="showBugs"
      v-if="showBugs"
      :item="currentItem"
      :type="'ProcessStep'"
    />
    <Modal
      title="Ask AI"
      width="1100px"
      v-if="showAskGPT"
      @cancel="showAskGPT = false"
      @confirm="
        showAskGPT = false;
        sendChatGPT(currentItem);
      "
    >
      What do you want to ask:
      <v-select
        :items="GPTQuestions"
        item-text="question"
        item-value="question"
        outlined
        dense
        return-object
        v-model="selectedQuestion"
      ></v-select>
    </Modal>
    <GPTResponseDialogHTML
      v-if="showGPTResponse"
      v-model="showGPTResponse"
      :originalItem="currentItem"
      :gptResponse="gptResponse"
      @cancel="
        gptResponse = null;
        showGPTResponse = false;
      "
      @confirm="saveGPTResponse"
    />
  </div>
</template>

<script>
import StatusListIndividual from '@/components/StatusListIndividual';
import BugList from '@/components/general/BugList';
import ProcessStepEdit from '@/components/ProcessStep/ProcessStepEdit';
import feathersClient from '@/feathers-client';
import FieldListAdd from '@/components/ProcessStep/FieldListAdd';
import FieldEdit from '@/components/Fields/FieldEdit.vue';
import FieldValueList from '@/components/FieldValues/FieldValueList.vue';
import IssueList from '@/components/Issues/IssueList.vue';
import ReOrder from '@/components/ProcessStep/ReOrder';
import Updated from '@/components/general/Updated.vue';
import Response from '@/components/ProcessStep/StepResponses';
import VariantEdit from '@/components/Variant/VariantEdit.vue';
import DrawIO from '@/components/Drawing/drawio.vue';
import Group from '@/components/ProcessStep/Group/Grouplist.vue';
import GPTResponseDialogHTML from '@/components/GPTResponse/GPTResponseDialogHTML.vue';
import Modal from '@/components/general/Modal.vue';

import { makeGetMixin, makeFindMixin } from 'feathers-vuex';
import axios from 'axios';
import { subject } from '@casl/ability';
import {
  handleErrorResponse,
  handleSaveResponse,
} from '@/utils/MessageHandler';
import {
  getDocumentEmbed,
  getDocumentEmbedToken,
  getDocumentInfo,
  parseDocumentInfo,
} from '@/utils/LucidChartFunctions';

async function imageExists(url) {
  var result = false;
  result = await axios
    .head(url)
    .then((res) => res.status == 200)
    .catch(() => false);
  // console.log(url, result);
  return result;
}

export default {
  props: ['variant', 'statusId'],
  components: {
    ProcessStepEdit,
    FieldListAdd,
    FieldValueList,
    FieldEdit,
    ReOrder,
    Updated,
    Response,
    VariantEdit,
    StatusListIndividual,
    BugList,
    IssueList,
    DrawIO,
    Group,
    Modal,
    GPTResponseDialogHTML,
  },
  mixins: [
    makeGetMixin({
      service: 'process',
      id: 'processId',
      watch: 'processId',
      queryWhen: 'processId',
    }),
    makeFindMixin({
      service: 'process',
      name: 'fullProcessList',
      queryWhen: 'readyProcess',
    }),
    makeFindMixin({
      service: 'process-step',
      watch: 'variant.id',
      queryWhen: 'readyVariant',
    }),
    makeFindMixin({
      service: 'group',
      watch: 'variant.id',
    }),
    makeFindMixin({ service: 'status' }),
    makeFindMixin({
      service: 'tokens',
    }),
  ],
  data() {
    return {
      showBugs: false,
      detailDialog: false,
      detailVariantDialog: false,
      issueDialog: false,
      groupDialog: false,
      detailDialogAddField: false,
      detailDialogNewField: false,
      detailRemoveField: false,
      showResponse: false,
      currentItem: {},
      FieldId: null,
      Step: null,
      embedToken: null,
      importSteps: false,
      compareImportedSteps: [],
      importSelected: [],
      items: null,
      showReorder: false,
      detailDialogField: false,
      currentItemField: null,
      ErpApplications: [],
      loadingERP: false,
      images: [],
      show: [],
      list: [],
      gptResponse: null,
      showGPTResponse: false,
      showAskGPT: false,
      selectedQuestion: null,
      GPTQuestions: [
        {
          question: 'Verbeter NL',
          content:
            'Kan je deze tekst verbeteren of optimaliseren. Behoud de html formattering. Geef gewoon de vertaling terug geen extra meldingen, ook geen html indicatie errond.',
        },
        {
          question: 'Verbeter EN',
          content:
            'Can you correct or improve this text. Keep the htmlformatting. Just give the correction no extra messages also no html indication surrounding the response.',
        },
        {
          question: 'Translate NL->EN',
          content:
            'Kan je deze tekst vertalen naar het engels. Behoud de html formattering. Vette tekst <em> tags of tekst tussen dubbele quotes niet vertalen. Geef gewoon de vertaling terug geen extra meldingen.',
        },
      ],
      headers: [
        {
          text: 'Unlink',
          value: 'unlink',
        },
        {
          text: 'Nr',
          value: 'Number',
        },
        {
          text: 'Name',
          value: 'Name',
        },
        { text: 'Description', value: 'Description' },
        { text: '', value: '' },
        { text: '', value: 'data-table-expand' },
      ],
      headers2: [
        {
          text: 'Code',
          value: 'Code',
        },
        {
          text: 'Value',
          value: 'Value',
        },
        { text: 'Description', value: 'Description' },
      ],
      headers3: [
        {
          text: 'Imported Drawing Text',
          value: 'text',
          sortable: true,
          groupable: false,
        },
        { value: 'arrow', sortable: false },
        {
          text: 'Loxi Text',
          value: 'loxiText',
          sortable: true,
          groupable: false,
        },
      ],
    };
  },
  watch: {
    process: async function () {
      if (
        this.$feature('lucidChartIntegration') &&
        this.process &&
        this.process.ExternalDocumentId
      ) {
        await feathersClient.service('process').get(this.processId);
        await this.LucidEmbed();
      } else if (this.$feature('cloudinaryChartIntegration')) {
        await this.getImages();
      }
    },
    'variant.id': async function () {
      if (this.variant?.id) {
        this.loadingERP = true;
        this.ErpApplications = [];
        const { erpapplication_variant, erp_application } =
          this.$FeathersVuex.api;
        let app_variant = await erpapplication_variant.find({
          query: {
            VariantId: this.variant?.id ? parseInt(this.variant?.id) : 0,
          },
        });
        if (app_variant.total > 0) {
          app_variant.data.forEach(async (a) => {
            if (a.ErpApplicationId) {
              let app = await erp_application.get(a.ErpApplicationId);
              this.ErpApplications.push(app);
            }
          });
          this.loadingERP = false;
        }
      }
    },
  },
  computed: {
    readyVariant() {
      return this.variant != null;
    },
    readyProcess() {
      return this.process && this.process.DepartmentId != null;
    },
    fullProcessListParams() {
      return {
        query: {
          DepartmentId: this.process ? this.process.DepartmentId : 0,
          $sort: { Number: 1 },
        },
      };
    },
    processStepParams() {
      return {
        query: {
          VariantId: this.variant ? parseInt(this.variant.id) : 0,
          $sort: {
            Number: 1,
          },
        },
      };
    },
    groupParams() {
      return {
        query: {
          VariantId: this.variant?.id,
        },
      };
    },
    processId() {
      return this.variant ? this.variant.ProcessId : 0;
    },
    sortedVariants() {
      let variants = [...this.process.variants];
      return variants.sort((a, b) => (a.Number > b.Number ? 1 : -1));
    },
    statusParams() {
      return { query: { Active: true, $sort: { Number: 1 } } };
    },
    tokensParams() {
      return {};
    },
    LucidUrl() {
      return `https://lucid.app/documents/${
        this.process.ExternalDocumentId
      }/viewer?pages=${
        this.variant.PageIndexes
          ? this.variant.PageIndexes
          : this.variant.Number + 1
      }&token=${this.embedToken}`;
    },
    fullProcess() {
      if (this.variant) {
        return (
          this.$appConfig.customer.prefix +
          this.process.Number.toString().padStart(4, '0') +
          '.' +
          this.variant.Number.toString().padStart(2, '0') +
          ' ' +
          this.variant.Name
        );
      } else {
        return 'Process Steps';
      }
    },
    processUrl() {
      let url = '';
      if (this.variant) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.process.Number.toString() +
          '.' +
          this.variant.Number.toString();

        // '/ATS/ATS' +
        // currentProcess.Number.toString().padStart(4, '0') +
        // '.' +
        // currentVariant.Number.toString().padStart(2, '0');
      }
      return url;
    },
    processUrlFull() {
      let url = '';
      if (this.variant) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.$appConfig.customer.prefix +
          this.process.Number.toString().padStart(4, '0') +
          '.' +
          this.variant.Number.toString().padStart(2, '0');
      }
      return url;
    },
    sharePointUrl() {
      if (this.tokens) {
        let url = this.tokens.filter((t) => t.Application == 'SharePoint');
        if (url && url.length == 1) {
          return `${url[0].FrontEndUrl}${
            this.$appConfig.customer.prefix
          }${this.process.Number.toString().padStart(4, '0')}`;
        }
      }
      return '';
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  methods: {
    askChatGPT(item) {
      this.showAskGPT = true;
      this.currentItem = item;
    },
    sendChatGPT(item) {
      let data = JSON.stringify({
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'user',
            content: `${this.selectedQuestion.content} ${item.Description}`,
            // content: `Kan je deze tekst vertalen naar het engels. Behoud de html formattering. Vette tekst <em> tags of tekst tussen dubbele quotes niet vertalen. Geef gewoon de vertaling terug geen extra meldingen. ${item.Description}`,
            // content: `Kan uitgebreide tekst  genereren over deze punten. Formatteer in html. Geen exztra output aub ook geen html ''' errond${item.Description}`,
          },
        ],
      });

      console.log(data);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.openai.com/v1/chat/completions',
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer 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',
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          this.gptResponse = response.data.choices[0].message.content;
        })
        .catch((error) => {
          console.log(error);
        });

      this.showGPTResponse = true;
    },
    async saveGPTResponse(response) {
      this.currentItem.Description = response;
      await this.currentItem.save();
      this.gptResponse = null;
      this.showGPTResponse = false;
    },
    accentStyle(colors) {
      let boxShadow = '';

      // Start looping from the second color (index 1)
      colors.slice(1).forEach((color, index) => {
        const offset = (index + 1) * -5; // Calculate the offset for each color
        boxShadow += `${offset}px 0 0 0 ${color}, `;
      });

      // Remove the trailing comma and space from the last box-shadow entry
      boxShadow = boxShadow.slice(0, -2);

      return {
        'border-left': `5px solid ${colors[0]}`, // First border with the first color
        'box-shadow': boxShadow,
      };
    },
    subject,
    handleErrorResponse,
    handleSaveResponse,
    checkEditVariant(item) {
      return this.$can('update', subject('variant', item));
    },
    async assignStatus(item, id) {
      const { ProcessStep } = this.$FeathersVuex.api;
      // console.log(item, id);

      try {
        await feathersClient.service('process-step').patch(item.id, {
          StatusId: id,
        });
      } catch (error) {
        handleErrorResponse(error);
      }
      // handleSaveResponse('', 'Test Scenario', 'assigned');
      await ProcessStep.find({ query: { id: item.id } });
    },
    toggle(i) {
      if (this.show[i] == undefined) {
        this.show[i] = true;
        this.show = [...this.show];
      } else {
        this.show[i] = !this.show[i];
        this.show = [...this.show];
      }
    },
    row_classes(item) {
      if (item.text != item.loxiText) {
        return 'highlight';
      }
    },
    applyDark(item) {
      if (item) {
        var color = item.charAt(0) === '#' ? item.substring(1, 7) : item;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        var uicolors = [r / 255, g / 255, b / 255];
        var c = uicolors.map((col) => {
          if (col <= 0.03928) {
            return col / 12.92;
          }
          return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
        return L > 0.179 ? false : true;
      } else {
        return false;
      }
    },
    clickResponses(item) {
      this.currentItem = item;
      this.showResponse = true;
    },
    fullNumber(item) {
      return `${
        this.$appConfig.customer.prefix
      }${this.process.Number.toString().padStart(
        4,
        '0'
      )}.${this.variant.Number.toString().padStart(
        2,
        '0'
      )}.${item.Number.toString().padStart(2, '0')}`;
    },

    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    async editField(item) {
      const { Field } = this.$FeathersVuex.api;
      let field = await Field.find({
        query: {
          id: item.id,
        },
      });

      this.currentItemField = field.data[0];
      this.detailDialogField = true;
    },
    clickEditVariant() {
      this.detailVariantDialog = true;
    },
    clickEditFieldList(item) {
      this.currentItemFieldList = item;
      this.detailDialogFieldList = true;
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
    },
    clickAddNewField(item) {
      this.currentItem = item;
      this.detailDialogNewField = true;
    },
    clickRemoveField(item) {
      this.detailRemoveField = true;
      this.StepId = item.field_step.ProcessStepId;
      this.FieldId = item.field_step.FieldId;
    },
    clickAddField(item) {
      this.detailDialogAddField = true;
      this.StepId = item.id;
    },
    clickAddIssue(item) {
      this.issueDialog = true;
      this.Step = item;
      // console.log(item.id);
    },
    clickAddGroup() {
      this.groupDialog = true;
    },
    async getImages() {
      this.images = [];

      console.log(this.processUrl);

      for (let i = 0; i <= 3; i++) {
        let imageCheck = `${this.processUrl}${
          i == 0 ? '' : '-' + i.toString()
        }.svg`;
        // console.log(imageCheck);
        if (await imageExists(imageCheck)) {
          this.images.push(imageCheck);
        }
      }
      for (let i = 0; i <= 3; i++) {
        let imageCheck = `${this.processUrlFull}${
          i == 0 ? '' : '-' + i.toString()
        }.svg`;
        // console.log(imageCheck);
        if (await imageExists(imageCheck)) {
          this.images.push(imageCheck);
        }
      }
    },
    async LucidEmbed() {
      if (this.process && this.process.ExternalDocumentId) {
        if (!this.process.EmbedId) {
          await getDocumentEmbed(this.process);
          // console.log('No Embed');
        }
        this.embedToken = await getDocumentEmbedToken(this.process);
        // console.log('Embed', this.embedToken);
      } else {
        // console.log('No document Linked');
      }
    },
    async getSteps(variant) {
      let result = await getDocumentInfo(this.process);

      let steps = this.variant.process_steps.map((item) => {
        let newitem = {};
        newitem.id = item.id;
        newitem.ExternalId = item.ExternalId;
        newitem.text = item.Name;
        return newitem;
      });

      result =
        parseDocumentInfo(result)[
          variant.PageIndexes
            ? variant.PageIndexes.split(',')[0] - 1
            : variant.Number
        ];

      this.compareImportedSteps = result.blocks
        .filter((p) => p.type == 'ProcessStep' || p.type == 'ProcessManual')
        .map((item) => {
          let newitem = {};
          newitem.id = item.id;
          newitem.text = item.text;
          let loxi = steps.filter((item2) => item2.ExternalId == item.id);

          if (loxi && loxi.length == 1) {
            newitem.loxiId = loxi[0].id;
            newitem.loxiText = loxi[0].text;
          }

          return newitem;
        });

      this.importSteps = true;
    },
    cancelImport() {
      this.importSteps = false;
    },
    async saveImport() {
      if (this.importSelected.length > 0) {
        this.importSelected.forEach(async (item, index) => {
          // console.log(item.id);
          const { ProcessStep } = this.$FeathersVuex.api;
          if (!item.loxiId) {
            let newStep = new ProcessStep();
            newStep.Number = index + 1;
            newStep.ExternalId = item.id;
            newStep.ApplicationId = 1;
            newStep.Name = item.text;
            newStep.VariantId = this.variant.id;
            newStep.StatusId = 1;
            newStep.PriorityId = 1;
            await newStep.save();
          } else {
            let newStep = new ProcessStep();
            newStep.Name = item.text;
            // console.log(item.loxiId, newStep);
            await feathersClient.service('process-step').patch(item.loxiId, {
              Name: item.text,
            });
          }
          //await Process.find({ query: { id: this.process.id } });
        });
      }
      this.importSteps = false;
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },

    async unlinkField(item) {
      const { ProcessStep } = this.$FeathersVuex.api;

      if (item.field_step.id) {
        await this.$store.dispatch('field-step/remove', item.field_step.id);
        await ProcessStep.find({ query: {} });
      }
    },
  },
  async mounted() {
    // this.list = this.variant.process_steps.clone();
    if (
      this.$feature('lucidChartIntegration') &&
      this.process &&
      this.process.ExternalDocumentId
    ) {
      await this.LucidEmbed();
    } else if (this.$feature('cloudinaryChartIntegration')) {
      await this.getImages();
    }
    if (this.variant?.id) {
      this.loadingERP = true;
      this.ErpApplications = [];
      const { erpapplication_variant, erp_application } =
        this.$FeathersVuex.api;
      let app_variant = await erpapplication_variant.find({
        query: {
          VariantId: this.variant?.id ? parseInt(this.variant?.id) : 0,
        },
      });
      if (app_variant.total > 0) {
        app_variant.data.forEach(async (a) => {
          if (a.ErpApplicationId) {
            let app = await erp_application.get(a.ErpApplicationId);
            this.ErpApplications.push(app);
          }
        });
      }
      this.loadingERP = false;
    }
  },
};
</script>
<style lang="scss">
.obsolete {
  text-decoration: line-through;
}

.highlight {
  background-color: #ef9a9a;
}
.lucidchart-viewer {
  border: 0 !important;
}

.TitleBackground {
  background-color: #eeeeee !important;
}
</style>
