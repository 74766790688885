<template>
  <div>
    <v-dialog :value="true" persistent max-width="1400px">
      <div v-if="item">
        <v-form ref="form" v-model="validInput">
          <v-card flat class="px-5">
            <v-card-title>
              <span class="headline">New/Edit Test Scenario</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col align="right" cols="12" md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" @click="copyVariantName"
                        >mdi-arrow-right-bottom-bold</v-icon
                      >
                    </template>
                    <span>Copy Variant Name</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" md="10" class="justify-right">
                  <v-text-field
                    v-if="rerender"
                    v-model="item.Name"
                    label="Name"
                    :rules="[rules.required]"
                    :disabled="!$can('update', item, 'Name')"
                    outlined
                    hide-details="true"
                    dense
                    @change="fieldChanged"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="1">
                  <DrawIO
                    class="mt-1"
                    v-if="$feature('drawIOChartIntegration')"
                    :fullscreen="true"
                    :variantId="item.VariantId"
                    :iconOnly="true"
                  />
                </v-col>

                <v-col cols="12" md="12">
                  <RichEditor
                    v-model="item.Description"
                    :disabled="
                      !(
                        $can('update', item, 'Description') ||
                        $can('create', 'Description')
                      )
                    "
                    @change="fieldChanged"
                    placeholder="Description"
                  ></RichEditor>
                </v-col>
                <v-col class="pa-0 ma-0" cols="12" md="12" align="right"
                  ><v-btn icon @click="showProcessLinks = !showProcessLinks">
                    <v-icon>{{
                      showProcessLinks ? 'mdi-chevron-up' : 'mdi-chevron-down'
                    }}</v-icon>
                  </v-btn></v-col
                ></v-row
              >
              <v-expand-transition>
                <v-row v-if="showProcessLinks">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.variant.ProcessId"
                      :disabled="!$can('update', item, 'ProcessId')"
                      :items="processList"
                      item-text="fullname"
                      item-value="id"
                      label="Process"
                      outlined
                      clearable
                      dense
                      hide-details="true"
                      @change="item.VariantId = null"
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.VariantId"
                      :disabled="!$can('update', item, 'VariantId')"
                      :items="variantList"
                      item-text="fullname"
                      item-value="id"
                      :rules="[rules.required]"
                      label="Variant"
                      outlined
                      clearable
                      dense
                      hide-details="true"
                      @change="
                        item.variant.id = item.VariantId;
                        toggle();
                      "
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-expand-transition>
              <TestScenarioSteps
                class="my-5"
                v-if="item.VariantId && showSteps && item.id"
                :variant="item.variant"
                :testScenarioLine="item"
                :stepsToAdd="stepsToAdd"
                :addModus="addModus"
                @updateSteps="updateSteps"
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="$can('delete', item)"
                outlined
                @click="showRemoveMessage = true"
                >Remove</v-btn
              >
              <div v-if="showRemoveMessage" class="ml-5">
                Are you sure?
                <v-btn class="ml-2" @click="$emit('remove')" color="error"
                  >Yes</v-btn
                >
              </div>
              <v-spacer></v-spacer>
              <div v-if="!item.id">
                <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
                <v-btn
                  :disabled="!validInput"
                  color="primary"
                  :loading="saving"
                  @click="$emit('save')"
                >
                  Save
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  :disabled="!validInput"
                  color="primary"
                  :loading="saving"
                  @click="$emit('close')"
                >
                  Close
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import DrawIO from '@/components/Drawing/drawio.vue';
import { makeFindMixin } from 'feathers-vuex';
import TestScenarioSteps from './TestScenarioSteps.vue';
import RichEditor from '@/components/general/RichEditor.vue';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  name: 'TestScenarioEditDialog',
  components: { TestScenarioSteps, RichEditor, DrawIO },
  mixins: [
    makeFindMixin({
      service: 'variant',
      watch: ['item.VariantId', 'item.variant.ProcessId'],
    }),
    makeFindMixin({
      service: 'process',
    }),
  ],
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,
      showSteps: true,
      rerender: true,
      showProcessLinks: false,

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    stepsToAdd: {
      type: Array,
      required: false,
    },
    addModus: {
      type: Boolean,
      required: true,
    },
    saving: { type: Boolean },
  },
  computed: {
    variantParams() {
      return {
        query: { ProcessId: this.item.variant.ProcessId, $sort: { Number: 1 } },
      };
    },
    processParams() {
      return { query: { $sort: { Number: 1 } } };
    },
    processList() {
      let list = [...this.process];
      list.map((item) => {
        item.fullname = `${item.Number} ${item.Name}`;
      });
      return list;
    },
    variantList() {
      let list = [...this.variant];
      list.map((item) => {
        item.fullname = `${item.Number} ${item.Name}`;
      });
      return list;
    },
  },

  methods: {
    updateSteps(steps) {
      this.$emit('updateSteps', steps);
    },
    async toggle() {
      this.showSteps = false;
      await this.$nextTick();
      this.showSteps = true;
    },
    async copyVariantName() {
      this.item.Name = this.item.variant.Name;
      this.rerender = false;
      if (!this.addModus) {
        await this.fieldChanged();
      }
      await this.$nextTick();
      this.rerender = true;
    },
    async fieldChanged() {
      if (!this.addModus) {
        try {
          await this.item.save();
          handleSaveResponse(this.item.Name, 'Test Scanario Line', 'updated');
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    },
  },
};
</script>
