<template>
  <v-container>
    <v-overlay :value="generatingDocument">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <StatusSummary />

    <v-row dense>
      <v-col cols="12">
        <v-card class="mt-5">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer> </v-spacer>
            <v-btn
              v-if="$can('create', 'end-2-end')"
              elevation="0"
              class="mx-2"
              fab
              small
              color="primary"
              @click="clickAdd()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="filteredHeaders"
            :items="end2End"
            :search="search"
            sort-by="Number"
            disable-pagination
            hide-default-footer
            :loading="isFindEnd2EndPending"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                large
                class="mr-2"
                @click="clickDetail(item)"
                color="primary"
              >
                mdi-chevron-right
              </v-icon>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon
                v-if="$can('update', 'end-2-end')"
                small
                class="mr-2"
                @click="clickEdit(item)"
                color="primary"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="$can('print', 'end2end')"
                small
                class="mr-2"
                @click="startPrint(item)"
                color="primary"
              >
                mdi-printer-outline
              </v-icon>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <StatusListIndividual
                v-if="
                  filteredStatus(item.id) && filteredStatus(item.id).length > 0
                "
                :statusList="filteredStatus(item.id)"
              />
            </template>
            <template v-slot:[`item.issue`]="{ item }">
              <div
                v-if="filteredJira(item.Name)"
                @click="
                  openTab(
                    `https://leah4hifferman.atlassian.net/issues/?jql=status not in (Closed, Cancelled) AND 'End 2 End Process[Dropdown]' = '${item.Name}' order by created DESC`
                  )
                "
              >
                <JiraTasksProcess
                  v-if="filteredJira(item.Name)"
                  :list="filteredJira(item.Name)"
                />
              </div>
            </template>
            <template v-slot:[`item.progress`]="{ item }">
              <div
                v-if="
                  filteredStatus(item.id) && filteredStatus(item.id).length > 0
                "
              >
                <v-progress-linear
                  :value="
                    (filteredStatus(item.id)[0].Progress * 100).toFixed(2)
                  "
                  height="20"
                  dark
                  color="primary"
                >
                  <strong
                    >{{
                      (filteredStatus(item.id)[0].Progress * 100).toFixed(2)
                    }}%</strong
                  >
                </v-progress-linear>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <End2EndEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
    />
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import End2EndEdit from '@/components/End2End/End2EndEdit';
import StatusListIndividual from '@/components/StatusListIndividual';
import JiraTasksProcess from '@/components/Process/JiraTasksProcess';
import StatusSummary from '@/components/StatusSummary';

import { mapMutations } from 'vuex';

import { printingEnd2End } from '@/utils/DocExport';

export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: '',
          value: 'edit',
          sortable: false,
          groupable: false,
        },
        { text: 'Nr', value: 'Number', groupable: false },
        { text: 'End 2 End Process', value: 'Name', groupable: false },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Progress',
          value: 'progress',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
        },
      ],
      detailDialog: false,
      currentItem: [],
      generatingDocument: false,
    };
  },
  computed: {
    filteredHeaders() {
      let filtered = this.headers.filter((item) => {
        return this.$can('update', 'end-2-end') ||
          this.$can('print', 'end-2-end')
          ? true
          : item.value != 'edit';
      });
      filtered = filtered.filter((item) => {
        return this.$appConfig.externalIssues ? true : item.value != 'issue';
      });
      return filtered;
    },
    end2EndParams() {
      return {};
    },
    statusOverviewParams() {
      return { query: { end2end: true } };
    },
    jiraParams() {
      return { query: { type: this.$appConfig.externalIssues } };
    },
  },
  mixins: [
    makeFindMixin({ service: 'end-2-end' }),
    makeFindMixin({ service: 'status-overview' }),
    makeFindMixin({ service: 'jira' }),
  ],
  components: {
    StatusListIndividual,
    End2EndEdit,
    JiraTasksProcess,
    StatusSummary,
  },
  methods: {
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    openTab(url) {
      window.open(url, '_blank');
    },
    end2EndProcessList(id) {
      if (this.end2End) {
        let end2endProcess = this.end2End.filter((item) => item.id == id);
        if (
          end2endProcess &&
          end2endProcess.length &&
          end2endProcess.length > 0
        )
          return end2endProcess[0].processes.map((a) => a.id);
      }
      return [];
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
    },
    clickDetail(clickedEnd2End) {
      this.setActiveEnd2End(clickedEnd2End);
      this.$router.push({
        name: 'Process',
        params: { id: clickedEnd2End.id },
      });
    },
    filteredStatus(id) {
      if (this.statusOverviewLatestQuery) {
        return this.statusOverviewLatestQuery.response.filter(
          (item) => item.End2EndId == id
        );
      } else return [];
    },
    filteredJira(value) {
      if (
        this.jiraLatestQuery &&
        this.jiraLatestQuery.response &&
        this.jiraLatestQuery.response.length > 0 &&
        this.jiraLatestQuery.response[0].issues
      ) {
        return this.jiraLatestQuery.response[0].issues.filter((j) => {
          return j.fields && j.fields.customfield_10053
            ? j.fields.customfield_10053.value == value
            : false;
        });
      } else return null;
    },
    ...mapMutations(['setActiveEnd2End']),
    async startPrint(item) {
      this.generatingDocument = true;
      await printingEnd2End(item);
      this.generatingDocument = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
